body{
    min-height: auto !important;
}
.MuiFilledInput-input {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
}
.MuiFilledInput-underline:after, .MuiFilledInput-underline:before {
    display: none;
}
.login-page .login-panel .form .MuiTextField-root{
    box-shadow:none !important;
}
.eyeButton{
  margin-top:0px !important;
  height: 32px !important;
  background: none !important;
  color: #515151 !important;
}
.TextButton{
  background: none !important;
  color: #00b0f0 !important;
  text-transform: none !important;
}
.login-page .login-panel .form{
    margin-top: 36px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    width:50% !important;
    text-transform: none !important;
}
.login-page .login-panel{
    background: none !important;
}
.login-page .login-panel .logo{
    margin-top: 20px;
}
.css-10botns-MuiInputBase-input-MuiFilledInput-input:invalid, 
.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input:invalid{
    box-shadow: none;
    border: 1px solid red !important;
    background: #f7e9e9 !important;
}
[data-amplify-authenticator] {
    display: flex !important;
    margin: 0px auto 0px auto;
    text-align: center;
    padding-top: 30px;
}
[data-amplify-authenticator], [data-amplify-container]{
    background: #fff !important;

}
[data-amplify-authenticator] ,[data-amplify-router]{
    border:none !important;
    box-shadow: none !important;
    margin-bottom: 20px;
}
[data-amplify-authenticator] [data-amplify-form]{
    padding: 45px !important;
}
[data-amplify-footer]{
    padding-bottom: 10px !important;
    width: 47% !important;
}
.amplify-label{
    display: none !important;
}
.amplify-field-group__field-wrapper{
    text-align: left !important;
}
.app-name-font{
    font-weight: 600 !important;
    padding-top: 25px;
}
.amplify-button--primary{
    background: #00b0f0 !important;
}
.logo{
    margin-top:10px !important;
}
.date-picker-container{
    width: 30%;
    margin-right: 60px !important;
}
.date-picker-container .date-picker{
    width:94% !important;
}
.date-picker-container h5{
    margin-right: 15px;
    font-size: 16px !important;
}
.date-picker-container, .pallet-list-custom-select-container{
    display: block !important;
}
.pallet-list-customers .pallet-list-custom-select-container{
    margin: 2px 0px 0 0;
    width: 31%;
}
.pallet-list-customers>div:nth-child(2){
    margin: 2px 85px 0 0;
}
.pallet-list-customers .pallet-list-custom-select-container h5{
    font-size: 16px !important;
}
.select-form-cont{
    width:99%;
}
.login-panel{
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 20px;
}
.sso-cont{
    display: block;
    position: absolute;
    width: 44%;
    right: 0px;
    bottom: 67px;
}
.SSO-button{
    background: none !important;
    border: none !important;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #047d95 !important;
    padding: 5px 10px !important;
    text-transform: none !important;
    border-radius: 5px !important;
    font-family: inherit !important;
}
.SSO-button:hover{
    background-color: #e9f9fc !important;
    color: #005566 !important;
}
.weight-heuristic-chip{
    margin-bottom: 2px !important;
}